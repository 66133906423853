import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
  name: 'user',
  initialState: {},
  extraReducers: {
    login: (state, action) => {
      return { ...state, isAuth: true };
    },
    loadAccount: (state, action) => {
      return { ...state, info: action.payload };
    },
    logout: (state, action) => {
      return { ...state, isAuth: false };
    },
  },
});

export const { login, loadAccount, logout } = usersSlice.actions;

export default usersSlice.reducer;
