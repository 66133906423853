import axios from 'axios';

const base_url = process.env.REACT_APP_API_URL;
// const base_url = 'http://localhost:3001';

const old_api =
  'https://zk8lz1sy87.execute-api.us-east-1.amazonaws.com/production/api/v1';

const api = async (
  path = '/',
  method = 'GET',
  data = {},
  use_old_api = false,
  auth = { status: false, access_token: '' }
) => {
  const { status, access_token } = auth;
  let api_url = base_url;

  if (status) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
  }

  if (use_old_api) {
    api_url = old_api;
  }

  if (method === 'GET') {
    const { data: res }: any = await axios.get(`${api_url}${path}`);
    return res;
  }

  if (method === 'POST') {
    const { data: res }: any = await axios.post(`${api_url}${path}`, data);
    return res;
  }

  if (method === 'PUT') {
    const { data: res }: any = await axios.put(`${api_url}${path}`, data);
    return res;
  }

  if (method === 'DELETE') {
    const { data: res }: any = await axios.delete(`${api_url}${path}`, data);
    return res;
  }

  if (method === 'PATCH') {
    const { data: res }: any = await axios.patch(`${api_url}${path}`, data);
    return res;
  }
};

export default api;
