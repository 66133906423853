import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

export default function Logo({ sx }: LogoProps) {
  return (
    <Box sx={{ width: 80, ...sx }}>
      <img src="/logo.svg" alt="Logo Greether" />
    </Box>
  );
}
