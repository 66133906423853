import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import api from 'utils/api';
import { isValidToken, setSession } from 'utils/jwt';
import { ROUTES } from './routes';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.FC<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const dispatch = useDispatch();
  const tokenLocal = localStorage.getItem('accessToken');
  const hasToken = isValidToken(tokenLocal);
  const state = useSelector((state: any) => state.user);

  useEffect(() => {
    const getUserInformation = async () => {
      if (!tokenLocal) return;
      return await api('/auth/my-account', 'POST', {}, false, {
        status: true,
        access_token: tokenLocal,
      });
    };

    if (Object.keys(state).length === 0 && hasToken) {
      getUserInformation().then((res) => {
        dispatch({ type: 'loadAccount', payload: res });
      });
    } else if (!hasToken) {
      setSession('');
    }
  }, [hasToken, dispatch, tokenLocal, state]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return hasToken ? <Component {...props} /> : <Redirect to={ROUTES.LOGIN} />
      }}
    />
  );
};

export default PrivateRoute;
