import { render } from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './styles/index.scss';

import App from './App';
import './index.css';
import './fonts/line-awesome-1.3.0/css/line-awesome.min.css';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
// import reportWebVitals from './reportWebVitals';

const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID || 'missing';

if (clientID === 'missing') {
  console.error('Missing clientID Google');
}

const rootElement = document.getElementById('root');
render(
  <GoogleOAuthProvider clientId={clientID}>
    <App />
  </GoogleOAuthProvider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
