export const dateFormatedString = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

export const fromStringToDate = (dateString: String) => {
  const [day, month, year] = dateString.split('/');
  const date = new Date(`${year}-${month}-${day}`);
  return date;
};

export const nextDayOfDate = (date: string | number | Date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  return newDate;
};

export const next3DaysOfDate = (date: string | number | Date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 3);
  return newDate;
};

export const next3BusinessDays = (date: string | number | Date) => {
  const newDate = new Date(date);

  for (let i = 0; i < 4; ) {
    newDate.setDate(newDate.getDate() + 1);

    // Check if the new date is a weekday (Monday to Friday)
    if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
      i++; // Increment only if it's a weekday
    }
  }

  return newDate;
};
